import React, {useEffect} from "react";
import {
    WrapperComponent,
    Loading,
} from "../components";
import {useRouter} from "next/router";
import {useRole} from "../helpers/RoleProvider";
import canIDoIt from "../helpers/PermissionHelper";

export default function Index() {
    const router = useRouter();
    const { query } = router;
    const {role} = useRole();
    useEffect(() => {
        if (query?.redirectTo && canIDoIt(role, query.redirectTo)) {
            router.push(query.redirectTo)
        } else {
            switch (role?.toUpperCase()) {
                case "ADMIN":
                    router.push("/mastercyber");
                    break;
                case "SALES":
                    router.push("/mastercyber");
                    break;
                case "MASTERCENTER":
                    router.push("/punticyber");
                    break;
                case "CENTER":
                    router.push("/ambassador");
                    break;
                case "AMBASSADOR":
                    router.push("/orders");
                    break;
                case "CUSTOMER":
                    router.push("/orders");
                    break;
                default:
                    router.push("/");
            }
        }
    }, []);

    return (
        <WrapperComponent>
            <Loading />
        </WrapperComponent>
    );
}
